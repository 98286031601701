import { FC, ReactNode } from "react";

import { InitialLoadingPageProps } from "../components/pages/initial-loading-page";
import { UserProvider } from "../features/user";
import { FluentProvider } from "../lib/fluentui";
import { SwrConfigProvider } from "../lib/swr";
import { TeamsFxContextProvider } from "../lib/teamsfx";
import "../lib/i18next";

interface Props {
  children: (initialLoading: InitialLoadingPageProps) => ReactNode;
}

export const Providers: FC<Props> = ({ children }) => (
  <TeamsFxContextProvider>
    {(isLoadingTeams) => (
      <FluentProvider>
        <SwrConfigProvider>
          <UserProvider>
            {(isLoadingUser) => children({ isLoadingTeams, isLoadingUser })}
          </UserProvider>
        </SwrConfigProvider>
      </FluentProvider>
    )}
  </TeamsFxContextProvider>
);
