import { Card, Spinner } from "@fluentui/react-components";
import { FC } from "react";
import { Trans } from "react-i18next";

interface Props {
  label: string;
  minutes?: number;
  isLoading: boolean;
}

export const DayTotalCard: FC<Props> = ({ label, minutes, isLoading }) => {
  return (
    <Card className="relative my-1 mr-2 h-16 w-32 justify-center bg-colorNeutralBackground5 font-semibold md:mb-4 lg:h-20 lg:w-44 2xl:w-60 dark:bg-colorNeutralBackground1">
      <span className="absolute left-1 top-1 md:left-2 md:top-2">
        <Trans>{`DAY_OVERVIEW.PRESENCE_VIEW.TIME_BOOKING.${label}`}</Trans>
      </span>
      <span className="mx-auto mt-2 text-xl hover:text-colorBrandBackgroundHover md:mt-3 lg:text-3xl dark:text-colorBrandBackground">
        {isLoading ? (
          <Spinner className="m-auto" />
        ) : minutes === undefined ? (
          "-"
        ) : (
          formatMinutes(minutes)
        )}
      </span>
    </Card>
  );
};

const formatMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const formattedHours = String(Math.abs(hours)).padStart(2, "0");
  const formattedMinutes = String(Math.abs(mins)).padStart(2, "0");

  return `${minutes < 0 ? "-" : ""}${formattedHours}:${formattedMinutes}`;
};
