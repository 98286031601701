import {
  FluentProvider as FluentProviderLib,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
} from "@fluentui/react-components";
import { FC, PropsWithChildren, useContext, useEffect } from "react";

import { TeamsFxContext } from "../../teamsfx/context";

export const FluentProvider: FC<PropsWithChildren> = ({ children }) => {
  const { themeString } = useContext(TeamsFxContext);

  useEffect(() => {
    const htmlElement = document.getElementById("time-look-html-root");
    if (htmlElement) {
      htmlElement.className = themeString;
    }
    return () => {
      if (htmlElement) {
        htmlElement.className = "";
      }
    };
  }, [themeString]);

  return (
    <FluentProviderLib
      theme={
        themeString === "dark"
          ? teamsDarkTheme
          : themeString === "contrast"
            ? teamsHighContrastTheme
            : {
                ...teamsLightTheme,
                colorNeutralBackground3: "#fff",
              }
      }
      style={{ background: tokens.colorNeutralBackground3 }}
    >
      {children}
    </FluentProviderLib>
  );
};
