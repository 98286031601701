import Axios, { AxiosRequestConfig } from "axios";

import config from "../../config";
import { teamsUserCredential } from "../teamsfx";

export const AXIOS_INSTANCE = Axios.create({
  baseURL: config.timeproEndpoint,
});

export const customInstance = async <T>(
  axiosConfig: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const accessToken = await teamsUserCredential.getToken("");

  if (!accessToken) {
    throw new Error("accessToken not found");
  }
  options = options ?? {};
  options.headers = options.headers ?? {};
  options.headers.common = options.headers.common ?? {};
  options.headers.common["Authorization"] = "Bearer " + accessToken.token;

  const source = Axios.CancelToken.source();
  const promise = await AXIOS_INSTANCE({
    ...axiosConfig,
    ...options,
    cancelToken: source.token,
  });

  return promise.data;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
// export type ErrorType<Error> = AxiosError<Error>;
//
// export type BodyType<BodyData> = BodyData;
//
// // Or, in case you want to wrap the body type (optional)
// // (if the custom instance is processing data before sending it, like changing the case for example)
// export type BodyType<BodyData> = CamelCase<BodyData>;
