import {
  ChevronLeft24Filled,
  ChevronLeft24Regular,
  ChevronRight24Filled,
  ChevronRight24Regular,
} from "@fluentui/react-icons";
import type { Dispatch, FC, SetStateAction } from "react";

import { HoverIcon } from "../../../components/icons/hover-icon";
import { DatePicker } from "../../../lib/fluentui/components/date-picker";

interface Props {
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
}

export const DayOverviewDatePicker: FC<Props> = ({
  currentDate,
  setCurrentDate,
}) => {
  const onSelectDate = (date: Date | null | undefined) => {
    if (date) {
      // doing setHours, otherwise it's midnight with time zone offset of +0200
      // which causes toISOString to flip back to the day before:
      // new Date("Fri Sep 27 2024 00:00:00 GMT+0200").toISOString() returns '2024-09-26T22:00:00.000Z'
      date.setHours(12);
      setCurrentDate(date);
    }
  };

  const onDay = (direction: "next" | "prev") => () => {
    setCurrentDate((currentDate) => {
      const nextDate = new Date(currentDate);
      const dateAddend = direction === "next" ? 1 : -1;
      nextDate.setDate(currentDate.getDate() + dateAddend);
      return nextDate;
    });
  };

  return (
    <>
      <HoverIcon
        DefaultIcon={ChevronLeft24Regular}
        HoveredIcon={ChevronLeft24Filled}
        iconProps={{
          className: "mr-1",
          onClick: onDay("prev"),
        }}
      />
      <DatePicker
        size="large"
        firstDayOfWeek={1}
        onSelectDate={onSelectDate}
        value={currentDate}
      />
      <HoverIcon
        DefaultIcon={ChevronRight24Regular}
        HoveredIcon={ChevronRight24Filled}
        iconProps={{
          className: "mr-1",
          onClick: onDay("next"),
        }}
      />
    </>
  );
};
