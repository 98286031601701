import { FC } from "react";

import { PresenceDayTotalModel } from "../../../api/model/presenceDayTotalModel";

import { DayTotalCard } from "./day-total-card";

interface Props {
  dayTotal?: PresenceDayTotalModel;
  isLoading: boolean;
}

export const DayTotals: FC<Props> = ({ dayTotal, isLoading }) => {
  const cards = [
    { label: "FLEX_TIME_DIFFERENCE", minutes: dayTotal?.flexTimeDifference },
    { label: "TARGET", minutes: dayTotal?.targetValue },
    { label: "BREAK", minutes: dayTotal?.breakTime },
    { label: "ROUNDED", minutes: dayTotal?.roundedValue },
    { label: "EFFECTIVE", minutes: dayTotal?.effectiveValue },
  ];

  return (
    <div className="-m-2 flex flex-wrap justify-between">
      {cards.map(({ label, minutes }, index) => (
        <DayTotalCard
          key={index}
          label={label}
          minutes={minutes}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};
