import { FC } from "react";
import { Routes as RoutesLib, Route, Navigate } from "react-router-dom";

import DayOverviewPage from "../components/pages/day-overview-page";

export const Routes: FC = () => {
  return (
    <RoutesLib>
      <Route path="/" element={<DayOverviewPage />} />
      <Route path="*" element={<Navigate to={"/"} />}></Route>
    </RoutesLib>
  );
};
