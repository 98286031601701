import {
  TeamsUserCredentialAuthConfig,
  TeamsUserCredential,
} from "@microsoft/teamsfx";

import config from "../../config";

if (!config.initiateLoginEndpoint || !config.clientId) {
  throw new Error(
    `Configuration is missing values clientId or initiateLoginEndpoint: ${JSON.stringify(config)}`,
  );
}

export const teamsAuthConfig: TeamsUserCredentialAuthConfig = {
  initiateLoginEndpoint: config.initiateLoginEndpoint,
  clientId: config.clientId,
};

export const teamsUserCredential = new TeamsUserCredential(teamsAuthConfig);
