import { ToasterProps } from "../../components/toast";

import { useToast } from "./use-toast";

export const useErrorToast = () => {
  const usingToast = useToast("error-toaster", "error");
  const trigger = ({
    message = "GENERAL.ERROR_MESSAGE",
    subtitle,
  }: ToasterProps) => usingToast.trigger({ message, subtitle });

  return { component: usingToast.component, trigger };
};
