/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * My Title
 * OpenAPI spec version: 1.0.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";

import type {
  AbsenceBookingModel,
  AbsenceMetadataModel,
  BookingModel,
  OutlookAddinDayOverviewParams,
  UserDetailsLightView,
} from ".././model";
import { customInstance } from "../../lib/axios/instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const outlookAddinStamp = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<string>(
    { url: `/api/rest/outlook-addin/stamp`, method: "POST" },
    options,
  );
};

export const getOutlookAddinStampMutationFetcher = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<string> => {
    return outlookAddinStamp(options);
  };
};
export const getOutlookAddinStampMutationKey = () =>
  [`/api/rest/outlook-addin/stamp`] as const;

export type OutlookAddinStampMutationResult = NonNullable<
  Awaited<ReturnType<typeof outlookAddinStamp>>
>;
export type OutlookAddinStampMutationError = unknown;

export const useOutlookAddinStamp = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof outlookAddinStamp>>,
    TError,
    Key,
    Arguments,
    Awaited<ReturnType<typeof outlookAddinStamp>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getOutlookAddinStampMutationKey();
  const swrFn = getOutlookAddinStampMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const outlookAddinDayOverview = (
  params?: OutlookAddinDayOverviewParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<BookingModel[]>(
    { url: `/api/rest/outlook-addin/day-overview`, method: "GET", params },
    options,
  );
};

export const getOutlookAddinDayOverviewKey = (
  params?: OutlookAddinDayOverviewParams,
) =>
  [
    `/api/rest/outlook-addin/day-overview`,
    ...(params ? [params] : []),
  ] as const;

export type OutlookAddinDayOverviewQueryResult = NonNullable<
  Awaited<ReturnType<typeof outlookAddinDayOverview>>
>;
export type OutlookAddinDayOverviewQueryError = unknown;

export const useOutlookAddinDayOverview = <TError = unknown>(
  params?: OutlookAddinDayOverviewParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof outlookAddinDayOverview>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getOutlookAddinDayOverviewKey(params) : null));
  const swrFn = () => outlookAddinDayOverview(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};
export const outlookAddinMe = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UserDetailsLightView>(
    { url: `/api/rest/outlook-addin/me`, method: "GET" },
    options,
  );
};

export const getOutlookAddinMeKey = () =>
  [`/api/rest/outlook-addin/me`] as const;

export type OutlookAddinMeQueryResult = NonNullable<
  Awaited<ReturnType<typeof outlookAddinMe>>
>;
export type OutlookAddinMeQueryError = unknown;

export const useOutlookAddinMe = <TError = unknown>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof outlookAddinMe>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getOutlookAddinMeKey() : null));
  const swrFn = () => outlookAddinMe(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};
export const outlookAddinCreateAbsence = (
  absenceBookingModel: AbsenceBookingModel,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Blob>(
    {
      url: `/api/rest/outlook-addin/create-absence`,
      method: "POST",
      headers: { "Content-Type": "application/json-patch+json" },
      data: absenceBookingModel,
      responseType: "blob",
    },
    options,
  );
};

export const getOutlookAddinCreateAbsenceMutationFetcher = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return (_: Key, { arg }: { arg: AbsenceBookingModel }): Promise<Blob> => {
    return outlookAddinCreateAbsence(arg, options);
  };
};
export const getOutlookAddinCreateAbsenceMutationKey = () =>
  [`/api/rest/outlook-addin/create-absence`] as const;

export type OutlookAddinCreateAbsenceMutationResult = NonNullable<
  Awaited<ReturnType<typeof outlookAddinCreateAbsence>>
>;
export type OutlookAddinCreateAbsenceMutationError = unknown;

export const useOutlookAddinCreateAbsence = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof outlookAddinCreateAbsence>>,
    TError,
    Key,
    AbsenceBookingModel,
    Awaited<ReturnType<typeof outlookAddinCreateAbsence>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getOutlookAddinCreateAbsenceMutationKey();
  const swrFn = getOutlookAddinCreateAbsenceMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const outlookAddinAbsenceMeta = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AbsenceMetadataModel[]>(
    { url: `/api/rest/outlook-addin/absence-meta`, method: "GET" },
    options,
  );
};

export const getOutlookAddinAbsenceMetaKey = () =>
  [`/api/rest/outlook-addin/absence-meta`] as const;

export type OutlookAddinAbsenceMetaQueryResult = NonNullable<
  Awaited<ReturnType<typeof outlookAddinAbsenceMeta>>
>;
export type OutlookAddinAbsenceMetaQueryError = unknown;

export const useOutlookAddinAbsenceMeta = <TError = unknown>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof outlookAddinAbsenceMeta>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getOutlookAddinAbsenceMetaKey() : null));
  const swrFn = () => outlookAddinAbsenceMeta(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};
export const outlookAddinTriggerTimelookSync = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Blob>(
    {
      url: `/api/rest/outlook-addin/trigger-sync`,
      method: "POST",
      responseType: "blob",
    },
    options,
  );
};

export const getOutlookAddinTriggerTimelookSyncMutationFetcher = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<Blob> => {
    return outlookAddinTriggerTimelookSync(options);
  };
};
export const getOutlookAddinTriggerTimelookSyncMutationKey = () =>
  [`/api/rest/outlook-addin/trigger-sync`] as const;

export type OutlookAddinTriggerTimelookSyncMutationResult = NonNullable<
  Awaited<ReturnType<typeof outlookAddinTriggerTimelookSync>>
>;
export type OutlookAddinTriggerTimelookSyncMutationError = unknown;

export const useOutlookAddinTriggerTimelookSync = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof outlookAddinTriggerTimelookSync>>,
    TError,
    Key,
    Arguments,
    Awaited<ReturnType<typeof outlookAddinTriggerTimelookSync>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getOutlookAddinTriggerTimelookSyncMutationKey();
  const swrFn =
    getOutlookAddinTriggerTimelookSyncMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
