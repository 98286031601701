import { ToasterProps } from "../../components/toast";

import { useToast } from "./use-toast";

export const useSuccessToast = () => {
  const usingToast = useToast("success-toaster", "success");
  const trigger = ({ message = "GENERAL.SUCCESS", subtitle }: ToasterProps) =>
    usingToast.trigger({ message, subtitle });

  return { component: usingToast.component, trigger };
};
