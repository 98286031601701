import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { PresentoLanguageEnum } from "../api/model";
import de from "../assets/i18n/de.json";
import en from "../assets/i18n/en.json";
import fr from "../assets/i18n/fr.json";
import it from "../assets/i18n/it.json";

const translations: Record<string, any> = {
  en,
  de,
  fr,
  it,
};

type TimelookLanguage = keyof typeof translations;
export const presentoLanguageToI18next = (
  presentoLanguage: PresentoLanguageEnum,
): TimelookLanguage => {
  const map: Record<PresentoLanguageEnum, TimelookLanguage> = {
    [PresentoLanguageEnum.De]: "de",
    [PresentoLanguageEnum.En]: "en",
    [PresentoLanguageEnum.Fr]: "fr",
    [PresentoLanguageEnum.It]: "it",
    [PresentoLanguageEnum.Es]: "en", // fallback for Spanish is English
  };
  return map[presentoLanguage];
};

const supportedLngs = Object.keys(translations);
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: supportedLngs.reduce(
      (acc, lng) => ({
        ...acc,
        [lng]: { translation: translations[lng] },
      }),
      {},
    ),
    lng: "en",
    fallbackLng: "en",
    supportedLngs,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
