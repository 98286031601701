import { FC, PropsWithChildren } from "react";

import { Footer } from "./footer";
import { TopBar } from "./top-bar";

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const commonTopBarAndFooterClasses = "sticky z-50 bg-colorNeutralBackground3";
  return (
    <div className="mx-2 flex h-full flex-col lg:mx-4">
      <div className={`top-0 py-2 ${commonTopBarAndFooterClasses}`}>
        <TopBar />
      </div>

      <div className="grow pt-4 sm:mx-1 md:mx-4 lg:mx-6">{children}</div>

      <div className={`bottom-0 pb-2 ${commonTopBarAndFooterClasses}`}>
        <Footer />
      </div>
    </div>
  );
};
