import {
  Toast as ToastFluentUI,
  ToastBody,
  ToastTitle,
} from "@fluentui/react-components";
import { t } from "i18next";
import { FC } from "react";
import { Trans } from "react-i18next";

interface Props {
  title?: string;
  message?: string;
  subtitle: string;
}

export const Toast: FC<Props> = ({
  title = "GENERAL.ERROR",
  message = "GENERAL.ERROR_MESSAGE",
  subtitle,
}) => {
  return (
    <ToastFluentUI>
      <ToastTitle>
        <Trans>{title}</Trans>
      </ToastTitle>
      <ToastBody subtitle={subtitle}>{t(message)}</ToastBody>
    </ToastFluentUI>
  );
};

export type ToasterProps = Props;
