import { useState } from "react";

import { DayViewModel } from "../../../api/model";
import { useOutlookAddinDayOverview } from "../../../api/outlook-addin/outlook-addin";

/**
 * Custom hook to fetch and manage booking models for a specific date
 */
export const useDayViewModel = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const usingDayOverview = useOutlookAddinDayOverview({
    day: currentDate.toISOString().slice(0, 10),
  });

  // hack response type from BookingModel[] to DayViewModel, since the backend hasn't defined
  // it correctly cause this would involve resolving circular deps on the backend side
  // todo, rm type hack when backend is ready
  return {
    ...usingDayOverview,
    data: {
      ...usingDayOverview.data,
      data: usingDayOverview.data as unknown as DayViewModel,
    },
    refetch: () => usingDayOverview.mutate(usingDayOverview.data),
    currentDate,
    setCurrentDate,
  };
};

export type UsingDayViewModel = ReturnType<typeof useDayViewModel>;
