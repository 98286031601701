import { ReactElement } from "react";

import { useErrorToast } from "../lib/fluentui/hooks/toast/use-error-toast";

/**
 * A custom hook that wraps a Promise function and handles any errors by displaying a toast message.
 *
 * @param promiseFunc A function that returns a Promise of type T
 * @returns A tuple containing a function that triggers the Axios promise and a React element for Toast container
 */
export const usePromiseWithErrorToaster = <T>(
  promiseFunc: () => Promise<T>,
): [() => Promise<void | T>, ReactElement] => {
  const usingErrorToaster = useErrorToast();
  const promiseWrapper = () => promiseFunc().catch(usingErrorToaster.trigger);

  return [promiseWrapper, usingErrorToaster.component];
};
