import { FluentIcon, FluentIconsProps } from "@fluentui/react-icons";
import { FC } from "react";
import { useHover } from "react-use";

interface Props {
  DefaultIcon: FluentIcon;
  HoveredIcon: FluentIcon;
  iconProps: FluentIconsProps;

  // If true, a rotation style is being applied to the icon.
  isLoading?: boolean;
}

export const HoverIcon: FC<Props> = ({
  DefaultIcon,
  HoveredIcon,
  iconProps,
  isLoading = false,
}) => {
  const iconPropsLocal = { ...iconProps };

  if (iconPropsLocal.onClick) {
    iconPropsLocal.className = `${iconPropsLocal.className ?? ""} action-icon`;
  }
  if (isLoading) {
    iconPropsLocal.className = `${iconPropsLocal.className ?? ""} rotate`;
  }

  return useHover((hovered) =>
    hovered ? (
      <HoveredIcon {...iconPropsLocal} />
    ) : (
      <DefaultIcon {...iconPropsLocal} />
    ),
  )[0];
};
