import { FC, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useOutlookAddinMe } from "../../../api/outlook-addin/outlook-addin";
import { presentoLanguageToI18next } from "../../../lib/i18next";
import { UserContext } from "../user.context";

interface Props {
  children: (isLoading: boolean) => ReactNode;
}

export const UserProvider: FC<Props> = ({ children }) => {
  const usingOutlookAddinMe = useOutlookAddinMe();
  const {
    i18n: { language, changeLanguage },
  } = useTranslation();

  useEffect(() => {
    if (usingOutlookAddinMe.data) {
      const currentLanguage = language;
      const userLanguage = presentoLanguageToI18next(
        usingOutlookAddinMe.data.language,
      );
      if (currentLanguage !== userLanguage) {
        changeLanguage(userLanguage);
      }
    }
  }, [changeLanguage, language, usingOutlookAddinMe.data]);

  return (
    <UserContext.Provider value={usingOutlookAddinMe}>
      {children(usingOutlookAddinMe.isLoading)}
    </UserContext.Provider>
  );
};
