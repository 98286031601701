import {
  ArrowEnterFilled,
  ArrowExitFilled,
  BinRecycleFilled,
  CommentFilled,
  DismissFilled,
  PauseFilled,
  PlayFilled,
  TagFilled,
} from "@fluentui/react-icons";

import { StampIconType } from "../../../api/model/stampIconType";

export const stampIcons: Record<StampIconType, JSX.Element> = {
  [StampIconType.ActiveTerminalStampComing]: <ArrowEnterFilled />,
  [StampIconType.ManualStampComing]: <ArrowEnterFilled />,
  [StampIconType.ActiveTerminalStampGoing]: (
    <ArrowExitFilled className="ml-auto pr-10" />
  ),
  [StampIconType.ManualStampGoing]: (
    <span className="ml-auto pr-10">
      <ArrowExitFilled />
    </span>
  ),
  [StampIconType.BreakGoing]: <PauseFilled className="ml-auto pr-10" />,
  [StampIconType.BreakComming]: <PlayFilled />,
  [StampIconType.DayRemark]: <CommentFilled />,
  [StampIconType.InactiveTerminalStamp]: <BinRecycleFilled />,
  [StampIconType.AbsenceDay]: <TagFilled />,
  [StampIconType.AbsenceMorning]: <TagFilled />,
  [StampIconType.AbsenceAfternoon]: <TagFilled />,
  [StampIconType.AbsenceRestOfDay]: <TagFilled />,
  [StampIconType.AbsenceHour]: <TagFilled />,
  [StampIconType.Transfer]: <TagFilled />,
  [StampIconType.Entitlement]: <TagFilled />,
  [StampIconType.LockCancel]: <TagFilled />,
  [StampIconType.BreakCancel]: <TagFilled />,
  [StampIconType.ErrorCancel]: <TagFilled />,
  [StampIconType.Information]: <DismissFilled />,
};
