import { useTranslation } from "react-i18next";

const indexArrayByNumber = (length: number) =>
  Array.from({ length }, (_, index) => index);

export const useTranslatedDatepickerStrings = () => {
  const { t } = useTranslation();

  return {
    months: indexArrayByNumber(12).map((index) =>
      t(`DATEPICKER.MONTHS.${index}`),
    ),
    shortMonths: indexArrayByNumber(12).map((index) =>
      t(`DATEPICKER.SHORT_MONTHS.${index}`),
    ),
    days: indexArrayByNumber(7).map((index) => t(`DATEPICKER.DAYS.${index}`)),
    shortDays: indexArrayByNumber(7).map((index) =>
      t(`DATEPICKER.SHORT_DAYS.${index}`),
    ),
    goToToday: t(`DATEPICKER.GO_TO_TODAY`),
    weekNumberFormatString: t(`DATEPICKER.WEEK_NUMBER_FORMAT_STRING`),
    prevMonthAriaLabel: t(`DATEPICKER.PREV_MONTH_ARIA_LABEL`),
    nextMonthAriaLabel: t(`DATEPICKER.NEXT_MONTH_ARIA_LABEL`),
    prevYearAriaLabel: t(`DATEPICKER.PREV_YEAR_ARIA_LABEL`),
    nextYearAriaLabel: t(`DATEPICKER.NEXT_YEAR_ARIA_LABEL`),
    prevYearRangeAriaLabel: t(`DATEPICKER.PREV_YEAR_RANGE_ARIA_LABEL`),
    nextYearRangeAriaLabel: t(`DATEPICKER.NEXT_YEAR_RANGE_ARIA_LABEL`),
    closeButtonAriaLabel: t(`DATEPICKER.CLOSE_BUTTON_ARIA_LABEL`),
    selectedDateFormatString: t(`DATEPICKER.SELECTED_DATE_FORMAT_STRING`),
    todayDateFormatString: t(`DATEPICKER.TODAY_DATE_FORMAT_STRING`),
    monthPickerHeaderAriaLabel: t(`DATEPICKER.MONTH_PICKER_HEADER_ARIA_LABEL`),
    yearPickerHeaderAriaLabel: t(`DATEPICKER.YEAR_PICKER_HEADER_ARIA_LABEL`),
    dayMarkedAriaLabel: t(`DATEPICKER.DAY_MARKED_ARIA_LABEL`),
  };
};
