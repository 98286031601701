import {
  Toaster,
  ToastIntent,
  useId,
  useToastController,
} from "@fluentui/react-components";

import { Toast, ToasterProps } from "../../components/toast";

export const useToast = (toasterIdString: string, intent: ToastIntent) => {
  const toasterId = useId(toasterIdString);
  const { dispatchToast } = useToastController(toasterId);

  const component = <Toaster toasterId={toasterId} />;

  const trigger = ({ message, subtitle }: ToasterProps) =>
    dispatchToast(<Toast message={message} subtitle={subtitle} />, {
      intent,
    });

  return { component, trigger };
};
