import { Button, ButtonProps, Spinner } from "@fluentui/react-components";
import { FC, useState } from "react";

import { usePromiseWithErrorToaster } from "../../../hooks/use-promise-with-error-toaster";

/**
 * AsyncButton component that handles asynchronous click events
 *
 * @param onClickPromise - A function that returns a Promise
 * @returns React component with a button that triggers the provided onClickPromise on click
 *
 * @throws Error - If the onClickPromise function throws an error, it will be caught and displayed in an error toaster component
 */
interface Props {
  onClickPromise: () => Promise<string | void>;
}

export const AsyncButton: FC<Props & ButtonProps> = ({
  onClickPromise,
  ...btnProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const wrappedPromise = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        return await onClickPromise();
      } finally {
        setIsLoading(false);
      }
    } else {
      return Promise.resolve();
    }
  };

  const [action, errorTosterComponent] =
    usePromiseWithErrorToaster(wrappedPromise);

  return (
    <>
      <Button
        {...btnProps}
        onClick={action}
        icon={isLoading ? <Spinner size="tiny" /> : null}
      >
        {btnProps.children}
      </Button>
      {errorTosterComponent}
    </>
  );
};
