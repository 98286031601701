import { ReactElement } from "react";

import { ToasterProps } from "../lib/fluentui/components/toast";
import { useSuccessToast } from "../lib/fluentui/hooks/toast/use-success-toast";

/**
 * A custom hook that wraps a Promise function and handles any errors by displaying a toast message.
 *
 * @param promiseFunc A function that returns a Promise of type T
 * @returns A tuple containing a function that triggers the Axios promise and a React element for Toast container
 */
export const usePromiseWithSuccessToaster = <T>(
  promiseFunc: () => Promise<T>,
  toasterPropsFunc: (data: T) => ToasterProps,
): [() => Promise<void | T>, ReactElement] => {
  const usingSuccessToaster = useSuccessToast();
  const promiseWrapper = () =>
    promiseFunc().then((data) =>
      usingSuccessToaster.trigger(toasterPropsFunc(data)),
    );

  return [promiseWrapper, usingSuccessToaster.component];
};
