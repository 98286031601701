import { ArrowSyncFilled, ArrowSyncRegular } from "@fluentui/react-icons";

import { Dayoverview } from "../../features/dayoverview";
import { useDayViewModel } from "../../features/dayoverview/hooks/use-day-view-model";
import { usePromiseWithErrorToaster } from "../../hooks/use-promise-with-error-toaster";
import { HoverIcon } from "../icons/hover-icon";
import { PageContent } from "../layout/page-content";

export default function DayOverviewPage() {
  const usingDayViewModel = useDayViewModel();

  const [refetch, errorTosterComponent] = usePromiseWithErrorToaster(
    usingDayViewModel.refetch,
  );

  const iconProps = {
    className: "w-5 h-5",
    onClick: refetch,
  };

  return (
    <PageContent
      title="DAY_OVERVIEW.TITLE"
      actions={[
        <HoverIcon
          key={0}
          DefaultIcon={ArrowSyncRegular}
          HoveredIcon={ArrowSyncFilled}
          iconProps={iconProps}
          isLoading={
            usingDayViewModel.isLoading || usingDayViewModel.isValidating
          }
        />,
      ]}
    >
      <Dayoverview {...usingDayViewModel} />
      {errorTosterComponent}
    </PageContent>
  );
}
