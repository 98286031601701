import { HashRouter as Router } from "react-router-dom";

import "../lib/i18next";
import { Layout } from "../components/layout";
import { InitialLoadingPage } from "../components/pages/initial-loading-page";

import { Providers } from "./providers";
import { Routes } from "./routes";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export const App = () => (
  <Providers>
    {(loadingContext) => (
      <Router>
        <Layout>
          <InitialLoadingPage {...loadingContext}>
            <Routes />
          </InitialLoadingPage>
        </Layout>
      </Router>
    )}
  </Providers>
);
