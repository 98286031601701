import { Title1 } from "@fluentui/react-components";
import { FC } from "react";
import { Trans } from "react-i18next";

import { UsingDayViewModel } from "../hooks/use-day-view-model";

import { DayOverviewDatePicker } from "./datepicker";
import { DayTable } from "./day-table";
import { DayTotals } from "./day-totals";

export const Dayoverview: FC<UsingDayViewModel> = ({
  currentDate,
  setCurrentDate,
  data,
  isLoading,
}) => {
  const dayViewModel = data.data;
  return (
    <>
      <DayOverviewDatePicker
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
      />
      <div className="section rounded border-2 border-solid border-colorNeutralBackground5 bg-colorNeutralBackground5">
        <Title1>
          <Trans>DAY_OVERVIEW.PRESENCE_VIEW.TITLE</Trans>
        </Title1>
      </div>

      {(dayViewModel?.presenceDay?.dayTotal ?? isLoading) && (
        <div className="section">
          <DayTotals
            dayTotal={dayViewModel?.presenceDay?.dayTotal}
            isLoading={isLoading}
          />
        </div>
      )}

      {dayViewModel?.presenceDay?.presenceBookings && (
        <div className="section">
          <DayTable bookings={dayViewModel.presenceDay.presenceBookings} />
        </div>
      )}
    </>
  );
};
