/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * My Title
 * OpenAPI spec version: 1.0.0
 */

export type StampIconType = (typeof StampIconType)[keyof typeof StampIconType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StampIconType = {
  ActiveTerminalStampComing: 1,
  ActiveTerminalStampGoing: 2,
  ManualStampComing: 3,
  ManualStampGoing: 4,
  BreakGoing: 5,
  BreakComming: 6,
  DayRemark: 7,
  AbsenceDay: 8,
  AbsenceMorning: 9,
  AbsenceAfternoon: 10,
  AbsenceRestOfDay: 11,
  AbsenceHour: 12,
  Transfer: 13,
  Entitlement: 14,
  Information: 15,
  LockCancel: 16,
  BreakCancel: 17,
  ErrorCancel: 18,
  InactiveTerminalStamp: 19,
} as const;
