import { Title1 } from "@fluentui/react-components";
import { FC, PropsWithChildren, ReactElement } from "react";
import { Trans } from "react-i18next";

interface Props extends PropsWithChildren {
  // translation key is expected for title
  title: string;
  actions: ReactElement[];
}

export const PageContent: FC<Props> = ({ children, title, actions }) => {
  return (
    <>
      <div className="flex w-full flex-row justify-between">
        <div className="ml-2">
          <Title1>
            <Trans>{title}</Trans>
          </Title1>
        </div>

        <div className="mr-2 self-center">{actions}</div>
      </div>

      <hr className="my-2 h-px border-0 bg-gray-300 dark:bg-gray-700" />

      <div className="mx-2">{children} </div>
    </>
  );
};
