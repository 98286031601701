import { AxiosError } from "axios";
import { FC, PropsWithChildren } from "react";
import { SWRConfig } from "swr";

import { useErrorToast } from "../../fluentui/hooks/toast/use-error-toast";

/**
 * A provider component that wraps the children components with SWRConfig to provide global configuration for SWR.
 * See the options: https://swr.vercel.app/docs/api#options
 *
 * @param children The child components to be wrapped by SWRConfig.
 * @returns The children components wrapped by SWRConfig with the specified configuration.
 */
export const SwrConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const usingErrorToaster = useErrorToast();

  const onError = (err: AxiosError) => {
    usingErrorToaster.trigger({ subtitle: `${err.message} (${err.code})` });
  };

  return (
    <SWRConfig
      value={{
        onError,
        // refreshInterval: 100, // useful for testing
      }}
    >
      {usingErrorToaster.component}
      {children}
    </SWRConfig>
  );
};
