import { Image } from "@fluentui/react-components";
import { FC } from "react";

import { useLogoByTheme } from "../../hooks/use-logo-by-theme";

export const Footer: FC = () => {
  const { timePro } = useLogoByTheme();
  return (
    <div className="flex w-full flex-row justify-between">
      <div>
        <Image className="w-20" src={timePro} />
      </div>

      <div className="self-end">
        <span className="mr-3">© {new Date().getFullYear()} zeit ag</span>
      </div>
    </div>
  );
};
