/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * My Title
 * OpenAPI spec version: 1.0.0
 */

export type PresentoLanguageEnum =
  (typeof PresentoLanguageEnum)[keyof typeof PresentoLanguageEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PresentoLanguageEnum = {
  De: 0,
  Fr: 1,
  It: 2,
  En: 3,
  Es: 4,
} as const;
