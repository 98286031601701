import {
  DatePicker as DatePickerFluentUI,
  DatePickerProps,
} from "@fluentui/react-datepicker-compat";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useTranslatedDatepickerStrings } from "../hooks/use-translated-datepicker-strings";

export const DatePicker: FC<DatePickerProps> = (props) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const onFormatDate = (date?: Date) =>
    date
      ? date.toLocaleDateString(currentLocale, {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "";

  return (
    <DatePickerFluentUI
      strings={useTranslatedDatepickerStrings()}
      formatDate={onFormatDate}
      {...props}
    />
  );
};
