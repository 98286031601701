import { Spinner } from "@fluentui/react-spinner";
import { FC, PropsWithChildren } from "react";
import { Trans } from "react-i18next";

interface Props {
  isLoadingTeams: boolean;
  isLoadingUser: boolean;
}

export const InitialLoadingPage: FC<PropsWithChildren<Props>> = ({
  isLoadingUser,
  isLoadingTeams,
  children,
}) => {
  if (!isLoadingTeams && !isLoadingUser) {
    return <>{children}</>;
  }

  return (
    <div className="m-auto flex size-full flex-col items-center justify-center">
      <Spinner size="extra-large" className="" />
      <span className="mx-auto font-semibold">
        <Trans>
          {isLoadingTeams && "LOADING.TEAMS"}
          {isLoadingUser && "LOADING.USER"}
        </Trans>
      </span>
    </div>
  );
};

export type InitialLoadingPageProps = Props;
