import { useContext } from "react";

import { TeamsFxContext } from "../lib/teamsfx/context";

export const useLogoByTheme = () => {
  const { themeString } = useContext(TeamsFxContext);
  const zeitAg =
    themeString === "default"
      ? "timelook_customer_logo_nav.png"
      : "timelook_customer_logo_nav_dark.png";

  const timePro = "timelook_time.pro-logo.png";
  return { zeitAg, timePro };
};
