import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  TableCellLayout,
} from "@fluentui/react-components";
import { TagFilled } from "@fluentui/react-icons";
import { FC, Fragment, PropsWithChildren } from "react";
import { Trans } from "react-i18next";

import { PresenceBookingModel } from "../../../api/model/presenceBookingModel";

import { stampIcons } from "./stamp-icons";

interface Props {
  bookings: PresenceBookingModel[];
}

const columns = [
  { label: "TYPE", style: { width: "5%" } },
  { label: "CODE", style: { width: "20%" } },
  { label: "BOOKING", style: { width: "20%" } },
  { label: "SOURCE", style: { width: "20%" } },
  { label: "REMARK", style: { width: "35%" } },
];

export const DayTable: FC<Props> = ({ bookings }) => {
  if (!bookings.length) {
    return <></>;
  }

  return (
    <Table arial-label="Default table" style={{ minWidth: "510px" }}>
      <TableHeader>
        <TableRow>
          {columns.map((column, index) => (
            <TableHeaderCell key={index} style={column.style}>
              <span className="font-semibold">
                <Trans>{`DAY_OVERVIEW.PRESENCE_VIEW.GRID.${column.label}`}</Trans>
              </span>
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {bookings.map((booking) => (
          <TableRow key={booking.id}>
            <TableCell>
              <span className="flex">
                {stampIcons[booking.iconType] ?? <TagFilled />}
              </span>
            </TableCell>
            <Cell>{booking.bookingText}</Cell>
            <Cell>{booking.stampCode}</Cell>
            <Cell>{booking.sourceText}</Cell>
            <Cell>
              {booking.remarks?.map((remark, index) => (
                <Fragment key={index}>{remark.text}</Fragment>
              ))}
            </Cell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const Cell: FC<PropsWithChildren> = ({ children }) => (
  <TableCell>
    <TableCellLayout>{children}</TableCellLayout>
  </TableCell>
);
