import { Image, Spinner } from "@fluentui/react-components";
import { FC } from "react";
import { Trans } from "react-i18next";

import { outlookAddinStamp } from "../../api/outlook-addin/outlook-addin";
import { useUser } from "../../features/user";
import { useLogoByTheme } from "../../hooks/use-logo-by-theme";
import { usePromiseWithSuccessToaster } from "../../hooks/use-promise-with-success-toaster";
import { AsyncButton } from "../../lib/fluentui/components/async-button";

export const TopBar: FC = () => {
  const { data, isLoading, error } = useUser();
  const { zeitAg } = useLogoByTheme();

  // todo, fix local env to make stamp work, than finish details
  const [outlookAddinStampWithToast, successToasterComponent] =
    usePromiseWithSuccessToaster(outlookAddinStamp, (_data) => {
      return { message: "todo", subtitle: "todo" };
    });

  return (
    <div className="flex w-full flex-row justify-between">
      <div>
        <Image className="w-24" src={zeitAg} />
      </div>

      {data ? (
        <div>
          <span className="mr-3">{data.lastFirstNameEmployeeNo}</span>
          {/* todo, add Success Toast (like in Timepro) */}
          <AsyncButton
            className="w-32"
            onClickPromise={outlookAddinStampWithToast}
            appearance="primary"
          >
            <Trans>STAMP.BUTTON</Trans>
          </AsyncButton>
        </div>
      ) : (
        isLoading &&
        !error && (
          <div>
            <span className="mr-3">
              <Spinner size="extra-small" className="mx-auto mt-1" />
            </span>
          </div>
        )
      )}
      {successToasterComponent}
    </div>
  );
};
