import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { FC, ReactNode } from "react";

import { TeamsFxContext } from "../context";
import { teamsAuthConfig } from "../credential";

interface Props {
  children: (isLoading: boolean, themeString: string) => ReactNode;
}

export const TeamsFxContextProvider: FC<Props> = ({ children }) => {
  const teamsUserCredential = useTeamsUserCredential(teamsAuthConfig);

  return (
    <TeamsFxContext.Provider value={teamsUserCredential}>
      {children(teamsUserCredential.loading, teamsUserCredential.themeString)}
    </TeamsFxContext.Provider>
  );
};
